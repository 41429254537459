import React from 'react';

import Header from '../partials/Header';
import HeroHome from '../partials/HeroHome';
import Articles from '../partials/Articles';
import { UserContext } from '../App';
import { useLocation } from 'react-router';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Home() {
  // 下面这两种方式都可以
  // const params = new URLSearchParams(window.location.search);
  let query = useQuery();
  const page = parseInt(query.get('page') ?? 1);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden mb-16">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page sections */}
        <HeroHome />
        <UserContext.Consumer>
          {({ user, _, __ }) => (
            <Articles user={user} page={page} />
          )}
        </UserContext.Consumer>

        {/* <FeaturesBlocks /> */}
        {/* <Testimonials /> */}
        {/* <Newsletter /> */}

      </main>

      {/*  Site footer */}
      {/* <Footer /> */}

    </div>
  );
}

export default Home;
