import React, { useState } from 'react';

import Header from '../partials/Header';
import { Navigate, useNavigate, useParams } from 'react-router';
import useFetch, { getApiUrl } from '../utils/network';
import style from './Article.module.css';
import { Link } from 'react-router-dom';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import { UserContext } from '../App';

function Article(props) {
  const user = props.user;

  let { id } = useParams();

  const [deleteDone, setDeleteDone] = useState();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const navigate = useNavigate();

  function gotoCompose() {
    navigate(`/compose/${article.Id}`);
  }

  const url = getApiUrl(`/api/v1/posts/${id}`, { token: user.token });

  const { status, data, error } = useFetch(url);

  const article = data.post;

  const handleDeleteClick = () => {
    setOpenConfirmDialog(true);
  }

  const handleCloseDialog = (e) => {
    setOpenConfirmDialog(false);
    if (e.currentTarget.value === 'yes') {
      deleteArticle();
    }
  }

  const deleteArticle = async () => {
    const url = getApiUrl('/api/v1/posts/' + id, { token: user.token })
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        mode: 'cors',
      })
      if (response.ok) {
        setDeleteDone(true);
      }
    } catch (e) {
      console.log(e);
    }
  }

  if (deleteDone) {
    return (
      <Navigate to='/' />
    );
  }

  const confirmDeleteDialog = (
    <Dialog
      open={openConfirmDialog}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"确定要删除这篇文章吗？"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          取消
          </Button>
        <Button value="yes" onClick={handleCloseDialog} color="secondary" autoFocus>
          确定
          </Button>
      </DialogActions>
    </Dialog>
  );

  const loadingUI = (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '50vh' }}
    >
      <Grid item xs={3}>
        <CircularProgress />
      </Grid>

    </Grid>
  );

  return (

    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {
                status === 'idle' && (
                  <>{loadingUI}</>
                )
              }

              {status === 'error' && <div>{error}</div>}
              {status === 'fetching' && (<>{loadingUI}</>)}

              {
                status === 'fetched' && article &&
                (
                  <div className="max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="min-h-screen overflow-hidden">

                      <div className="mb-10 p-5 bg-gray-100" key={article.Id} data-aos="fade-right">
                        <div className="mb-4">

                          {/* <Link to={`/article/${article.Id}`} className="h3">{article.Title}</Link>
                          <span className="pl-4 pr-2 text-gray-600 italic">by</span><Link to={`/articles/by/${article?.AuthorId}`} className="font-light">{article.AuthorInfo.FirstName}</Link> */}

                          <div className="mb-4">
                            <Link to={`/article/${article.Id}`} className="h4 text-gray-700 hover:underline pr-3">{article.Title}</Link>

                            <div className="mt-4 mb-4">
                              <Link to={`/articles/by/${article?.AuthorId}`} className="font-light text-xl">{article.AuthorInfo.FirstName}</Link>
                              <span className="text-gray-500 text-sm"> 发表于 {new Date(article?.CreatedAt).toLocaleDateString()}</span>

                              <UserContext.Consumer>
                                {({ user, _, __ }) => (
                                  user.Id === article?.AuthorInfo?.Id ?? "" ?
                                    <span className="float-right">
                                      <Button variant="contained" color="primary" onClick={gotoCompose}>编辑</Button>
                                      <span className='px-3'></span>
                                      <Button variant="contained" color="secondary" onClick={handleDeleteClick}>删除</Button>
                                    </span>
                                    : <></>
                                )}
                              </UserContext.Consumer>
                            </div>
                            <hr></hr>
                          </div>


                          {confirmDeleteDialog}

                        </div>

                        <div className={`text-xl leading-relaxed text-gray-600 ${style.article}`} dangerouslySetInnerHTML={{ __html: article.ContentHtml }} />

                      </div>

                    </div>
                  </div>
                )
              }

            </div>
          </div>
        </section>

      </main>
    </div>
  )
}

export default Article;
