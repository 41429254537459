import React, { useEffect, useState } from 'react';
import {
  Routes,
  Route,
  useLocation
} from 'react-router-dom';

import AOS from 'aos';
import { focusHandling } from 'cruip-js-toolkit';

import Home from './pages/Home';
import Admin from './pages/Admin';
import AdminUser from './pages/AdminUser';
import SignIn from './pages/SignIn';
import Compose from './pages/Compose';
import Article from './pages/Article';
import { apiGetUser } from './utils/network';
import UserArticlesPage from './pages/UserArticlesPage';
import Password from './pages/Password';

export const UserContext = React.createContext(null);

const KEY_TOKEN = 'token'

function App() {
  const token = localStorage.getItem(KEY_TOKEN)

  const [user, setUser] = useState({
    token: token ?? "",
  });

  useEffect(() => {
    let isMounted = true;

    if (token) {
      const refresh = async () => {
        const currentUser = await apiGetUser(token)

        if (!isMounted) return;

        if (currentUser) {
          currentUser.token = token;
          setUser(currentUser);
        } else {
          localStorage.removeItem(KEY_TOKEN);
        }
      };
      refresh();

      return () => { isMounted = false };
    }
  }, [token])

  const login = (user) => {
    setUser(user);
    localStorage.setItem(KEY_TOKEN, user.token);
  }

  const logout = () => {
    setUser({ token: "" });
    localStorage.removeItem(KEY_TOKEN);
    window.location.replace("/");
  }

  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      // disable: 'phone',
      // disable: true,
      duration: 700,
      easing: 'ease-out-cubic',
    });
  });

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  return (
    <UserContext.Provider value={{ user: user, login: login, logout: logout }}>
      <Routes>
        <Route exact path="/" element={<Home />} />

        <Route exact path="/articles/by/:userId?" element={<UserArticlesPage user={user} />} />
        <Route exact path="/articles/my" element={<UserArticlesPage user={user} />} />
        <Route exact path="/admin/users" element={<Admin />} />
        <Route exact path="/admin/users/:userId?" element={<AdminUser />} />
        <Route exact path="/profile/password" element={<Password user={user} />} />

        <Route path="/signin" element={
          <UserContext.Consumer>
            {({ user, login, _ }) => (
              <SignIn user={user} login={login} />
            )}
          </UserContext.Consumer>
               } />

        <Route path="/compose/:postId?" element={
          <UserContext.Consumer>
            {({ user, _, __ }) => (
              <Compose user={user} />
            )}
          </UserContext.Consumer>
               } />

        <Route path="/article/:id" element={
          <UserContext.Consumer>
            {({ user, _, __ }) => (
              <Article user={user} />
            )}
          </UserContext.Consumer>
               } />
      </Routes>

    </UserContext.Provider>
  );
}

export default App;
