import { FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { Navigate, useParams } from "react-router";
import Header from "../partials/Header";
import { getApiUrl } from "../utils/network";
import 'react-quill/dist/quill.snow.css';

export default function Compose(props) {
    const user = props.user;

    const [savedPostId, setSaved] = useState(null);

    const [article, setArticle] = useState({});
    const [title, setTitle] = useState('');
    const [access, setAccess] = useState('public');

    const editorRef = useRef(null);

    const { postId } = useParams();

    useEffect(() => {
        let isMounted = true;

        if (postId) {
            try {
                (async () => {
                    const url = getApiUrl('/api/v1/posts/' + postId, { token: user?.token ?? '' })
                    const response = await fetch(url);

                    if (!response.ok) return;

                    const data = await response.json();

                    if (!isMounted) return;

                    setArticle(data.post);
                    setTitle(data.post.Title);
                    setAccess(data.post.Access);

                })();
            } catch (exception) {
                console.log(exception);
            }
        }

        return () => { isMounted = false };
    }, [postId, user?.token]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        article.Content = JSON.stringify(article.Content);
        article.Title = title;
        article.Access = access;

        try {
            let id = postId;
            if (postId) {
                await fetch(getApiUrl('/api/v1/posts/' + postId, { token: user?.token }), {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    method: 'PUT',
                    body: JSON.stringify(article),
                });
            } else {
                const response = await fetch(getApiUrl('/api/v1/posts', { token: user?.token }), {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    method: 'POST',
                    body: JSON.stringify(article),
                });
                if (response.ok) {
                    const data = await response.json();
                    id = data.post.Id;
                }
            }
            setSaved(id);
        } catch (error) {
            console.log("save error: ", error);
        }

    };


    const onTitleChanged = (e) => {
        setTitle(e.target.value);
    }
    
    const onAccessChanged = (e) => {
        setAccess(e.target.value);
    }

    const handleEditorValueChanged = (value) => {
        setArticle({
            Content: editorRef.current.getEditor().getContents(),
            ContentHtml: value,
        });
    }

    if (!user.token) {
        const redirect = encodeURIComponent(window.location.pathname)
        return <Navigate to={`/signin?redirect=${redirect}`} />;
    }

    if (savedPostId) {
        return <Navigate to={'/article/' + savedPostId} />;
    }


    const modules = {
        toolbar: [
            // [{ 'header': [1, 2, false] }],
            [{ 'size': [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean']
        ],
    };

    const editor = (
        <>
            <ReactQuill
                id="editor"
                ref={editorRef}
                theme="snow"
                modules={modules}
                value={article?.ContentHtml ?? ''}
                onChange={handleEditorValueChanged}
                className="h-96" />
        </>
    );

    return (

        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <Header />

            <section className="bg-gradient-to-b to-white mt-10">
                <div className="max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="pt-32 pb-12 md:pt-20 md:pb-20">

                        {/* Form */}
                        <div className="mx-auto">
                            <form onSubmit={handleSubmit}>
                                <div className="flex flex-wrap -mx-3 mb-8">
                                    <div className="w-full px-3">
                                        {/* <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="title">文章标题</label> */}
                                        {/* <input value={article?.title || ''} id="title" className="form-input w-full text-gray-800" placeholder="请输入文章标题" required autoFocus /> */}
                                        <TextField value={title} onChange={onTitleChanged} id="title" className="form-input w-full text-gray-800" label="请输入文章标题" required autoFocus />
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <div className="w-full px-3">
                                        <div className="flex justify-between">
                                            <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="access">访问权限</label>
                                        </div>
                                        {/* <select id="access" className="form-input w-full text-gray-800" required>
                                            <option value="public">公开</option>
                                            <option value="private">私人</option>
                                        </select> */}
                                        <RadioGroup row aria-label="访问权限" name="access" value={access} onChange={onAccessChanged}>
                                            <FormControlLabel value="public" control={<Radio />} label="公开" />
                                            <FormControlLabel value="private" control={<Radio />} label="私人" />
                                        </RadioGroup>
                                    </div>
                                </div>

                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <div className="w-full px-3">
                                        <div className="flex justify-between">
                                            <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="content">文章标题</label>
                                        </div>
                                        {/* <Editor id="content" initialValue={article?.ContentHtml ?? ''} onValueChanged={handleEditorValueChanged} /> */}
                                        {editor}
                                    </div>
                                </div>

                                <div className="flex flex-wrap -mx-3 mt-20">
                                    <div className="w-full px-3">
                                        <button className="btn text-white bg-blue-600 hover:bg-blue-700 w-full">保存</button>
                                    </div>
                                </div>
                            </form>

                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
}
