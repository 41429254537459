import React from 'react';

import Header from '../partials/Header';
import Articles from '../partials/Articles';
import { useLocation, useParams } from 'react-router';
import { downloadZipFile, getApiUrl } from '../utils/network';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function UserArticlesPage(props) {
    let { userId } = useParams();
    if (!userId) {
        if (!props.user.token) {
            window.location.replace("/signin");
        }
        userId = props.user.Id;
    }
    let query = useQuery();
    const page = parseInt(query.get('page') ?? 1);

    const downloadArticles = async () => {
        console.log("downloadArticles")
        const url = getApiUrl(`/api/v1/users/${userId}/posts/zip`, { token: props.user.token });
        try {
            await downloadZipFile(url, props.user.LastName+props.user.FirstName+"的作品集.zip");
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <Header />

            {/*  Page content */}
            <main className="mt-20 pt-10">

                {/*  Page sections */}
                <>
                  <Articles byUid={userId} page={page} user={props.user} downloadArticles={downloadArticles} />
                </>
                {/* <FeaturesBlocks /> */}
                {/* <Testimonials /> */}
                {/* <Newsletter /> */}

            </main>

            {/*  Site footer */}
            {/* <Footer /> */}

        </div>
    );
}
