import { Snackbar } from '@mui/material';
import React from 'react';
import { Navigate } from 'react-router-dom';

import Header from '../partials/Header';
import { apiLogin } from '../utils/network';

const SignIn = (props) => {
  const user = props.user;
  const login = props.login;
  const [snackContent, setSnackContent] = React.useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("value:", e.target.username.value);
    const username = e.target.username.value;
    const password = e.target.password.value;

    if (!username || !password) {
      return;
    }

    const data = {
      username: username,
      password: password
    };

    try {
      const response = await apiLogin(data);
      if (!response.ok) {
        setSnackContent("用户名或密码错误");
        return;
      }

      const loggedUser = await response.json();

      login(loggedUser);
    } catch (error) {
      console.log("login error: ", error);
    }

  };

  const handleCloseSnackbar = () => {
    setSnackContent("");
  };

  if (user.token) {
    const redirect = new URL(window.location).searchParams.get('redirect');
    return <Navigate to={redirect || '/'} />;
  }

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <h1 className="font-light h3">欢迎回来！期待你的大作。</h1>
              </div>

              {/* Form */}
              <div className="max-w-sm mx-auto">
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="username">用户名</label>
                      <input id="username" className="form-input w-full text-gray-800" placeholder="请输入你的用户名" required autoFocus />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <div className="flex justify-between">
                        <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="password">密码</label>
                      </div>
                      <input id="password" type="password" className="form-input w-full text-gray-800" placeholder="请输入密码" required />
                    </div>
                  </div>

                  <div className="flex flex-wrap -mx-3 mt-6">
                    <div className="w-full px-3">
                      <button type="submit" className="btn text-white bg-blue-600 hover:bg-blue-700 w-full">登录</button>
                    </div>
                  </div>
                        <Snackbar
                            open={snackContent !== ""}
                            autoHideDuration={3000}
                            onClose={handleCloseSnackbar}
                            message={snackContent}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                        />
                </form>
              </div>

            </div>
          </div>
        </section>

      </main>

    </div>
  );
}

export default SignIn;
