import React from 'react';
import useFetch, { getApiUrl } from '../utils/network.js';
import { Link } from 'react-router-dom';
import { CircularProgress, Grid } from '@mui/material';

function AllUsers(props) {
  const user = props.user;
  const url = getApiUrl('/api/v1/users', {
    token: user?.token,
  });

  const { status, data, error } = useFetch(url);
  const users = data.users;

  const loadingUI = (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '50vh' }}
    >
      <Grid item xs={3}>
        <CircularProgress />
      </Grid>

    </Grid>
  );

  return (
    <section className="relative">

      {/* Section background (needs .relative class on parent and next sibling elements) */}
      {/* <div className="absolute inset-0 bg-gray-100 pointer-events-none" aria-hidden="true"></div> */}

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        {/* Section content */}
        <div>

          {/* Content */}
          <div className="lg:max-w-3xl md:max-w-2xl mx-auto">
            <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8">

              {status === 'idle' && (
                <>{loadingUI}</>
              )}

              {status === 'error' && <div>{error}</div>}
              {status === 'fetching' && (<>{loadingUI}</>)}
              {status === 'fetched' && users && (
                <>
                  <div className="grid grid-cols-4 gap-4">
                    {users.length === 0 && <div> No users found! </div>}
                     {users.map((user) => (
                       <div className="box-content shadow-xl rounded-2xl mb-8 p-4 bg-gray-100 text-center" key={user.Id}>
                         <Link to={`/admin/users/${user.Id}`} className="h4 text-gray-700 hover:underline">{user.LastName + user.FirstName}</Link>
                       </div>
                     ))}
                     </div>
                </>
              )}

            </div>
          </div>

          <div>

          </div>

        </div >


      </div >
    </section >
  );
}

export default AllUsers;
