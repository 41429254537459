import React from 'react';

import Header from '../partials/Header';
import AllUsers from '../partials/AllUsers';

export default function Admin(_) {
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <Header />

            {/*  Page content */}
            <main className="mt-20 pt-10">

                {/*  Page sections */}
                <>
                    <AllUsers />
                </>
                {/* <FeaturesBlocks /> */}
                {/* <Testimonials /> */}
                {/* <Newsletter /> */}

            </main>

            {/*  Site footer */}
            {/* <Footer /> */}

        </div>
    );
}
