import React from 'react';
import useFetch, { getApiUrl } from '../utils/network.js';
import { Link, useNavigate } from 'react-router-dom';
import style from '../pages/Article.module.css';
import { Button, Pagination } from '@mui/material';
import { CircularProgress, Grid } from '@mui/material';

function Articles(props) {
  const user = props.user;
  const byUid = props.byUid;
  const page = props.page ?? 1;

  const url = getApiUrl('/api/v1/posts', {
    token: user?.token,
    params: {
      uid: byUid ?? '',
      page: page,
    }
  });

  const { status, data, error } = useFetch(url);
  const articles = data.posts;

  if (data.authorInfo) {
    document.title = data.authorInfo.FirstName + "的作品";
  }

  // 页面首次进来时，自动滚动顶部。在点击下一页时很有用。
  // useEffect(() => {
  //   const unlisten = history.listen(() => {
  //     window.scrollTo(0, 0);
  //   });
  //   return () => {
  //     unlisten();
  //   }
  // }, []);

  const navigate = useNavigate();

  function gotoPage(page) {
    navigate(window.location.pathname + '?page=' + page);
  }

  const onPageChanged = (event, page) => {
    gotoPage(page);
  }

  const loadingUI = (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '50vh' }}
    >
      <Grid item xs={3}>
        <CircularProgress />
      </Grid>

    </Grid>
  );

  return (
    <section className="relative">

      {/* Section background (needs .relative class on parent and next sibling elements) */}
      {/* <div className="absolute inset-0 bg-gray-100 pointer-events-none" aria-hidden="true"></div> */}

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        {/* Section content */}
        <div>

          {/* Content */}
          <div className="lg:max-w-3xl md:max-w-2xl mx-auto">
            <div className="md:pr-4 lg:pr-12 xl:pr-16 mb-8">

              {status === 'idle' && (
                <>{loadingUI}</>
              )}

              {status === 'error' && <div>{error}</div>}
              {status === 'fetching' && (<>{loadingUI}</>)}
              {status === 'fetched' && articles && (
                <>
                  {data.authorInfo ? <div className='h3 mb-10'>{data.authorInfo.FirstName} 的作品</div> : ""}

                  {user.token && user.Id == byUid && <div className='mb-8'><Button variant='contained' onClick={props.downloadArticles}>打包下载</Button></div>}

                  {articles.length === 0 && <div> No articles found! </div>}
                  {articles.map((article) => (
                    <div className="box-content shadow-xl rounded-2xl mb-16 p-5 bg-gray-100 pl-8 pr-8" key={article.Id} data-aos="fade-right">
                      <div className="mb-4 pt-4">
                        <Link to={`/article/${article.Id}`} className="h4 text-gray-700 hover:underline pr-3">{article.Title}</Link>

                        <div className="mt-4 mb-4">
                          <Link to={`/articles/by/${article?.AuthorId}`} className="font-light text-xl">{article.AuthorInfo.FirstName}</Link>
                          <span className="text-gray-500 text-sm"> 发表于 {new Date(article?.CreatedAt).toLocaleDateString()}</span>
                        </div>
                        <hr></hr>
                      </div>

                      <div className={`text-gray-700 ${style.article}`} dangerouslySetInnerHTML={{ __html: article.ContentHtml }} />
                      {/* <div><Link className="float-right underline" to={"article/" + article.Id}>阅读全文 {'>'}</Link></div> */}
                    </div>
                  ))}
                </>
              )}

            </div>
          </div>

          {
            data ?
              <Pagination className="lg:max-w-3xl md:max-w-2xl mx-auto" onChange={onPageChanged} page={page} count={data.totoalPage} color="primary" />
              : <></>
          }

          <div>

          </div>

        </div >


      </div >
    </section >
  );
}

export default Articles;
