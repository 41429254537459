import React, { useState } from 'react';
import { getApiUrl} from '../utils/network.js';
import { UserContext } from '../App';

import Header from '../partials/Header';
import { useParams } from 'react-router';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

export default function Password(props) {
    const userId = props.user.Id;
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [snackContent, setSnackContent] = React.useState("");

    const changePassword = async (current_password, new_password) => {
        const url = getApiUrl('/api/v1/users/' + userId + '/password', { token: props.user.token })
        var passwordReq = {
            current_password: current_password,
            new_password: new_password,
        };
        // console.log(passwordReq)
        try {
            const response = await fetch(url, {
                headers: {
                    "Content-Type": "application/json",
                },
                method: 'PUT',
                body: JSON.stringify(passwordReq),
                mode: 'cors',
            })
            if (response.ok) {
                setSnackContent("修改密码成功！");
            } else if (response.status === 403) {
                setSnackContent("修改密码失败：旧密码输入错误");
            }
        } catch (e) {
            console.log(e);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const values = {
            current_password: data.get('current-password'),
            new_password: data.get('new-password'),
            new_password_repeat: data.get('new-password-repeat'),
        };
        // console.log(values);

        if (values.new_password !== values.new_password_repeat) {
            setSnackContent("两次输入的新密码不一致，请重新输入");
            return;
        }
        changePassword(values.current_password, values.new_password);
    };

    const handleCloseDialog = (e) => {
        setOpenConfirmDialog(false);
        // if (e.currentTarget.value === 'yes') {
        // }
    }

    const handleCloseSnackbar = () => {
        setSnackContent("");
    };


    const confirmResetDialog = (
        <UserContext.Consumer>
            {({ user, _, __ }) => (<>
                <Dialog
                    open={openConfirmDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"确定要重置密码吗？"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                            取消
                        </Button>
                        <Button value="yes" onClick={() => {
                            changePassword(user);
                            setOpenConfirmDialog(false);
                        }} color="secondary" autoFocus>
                            确定
                        </Button>
                    </DialogActions>
                </Dialog>
            </>)
            }
        </UserContext.Consumer>
    );

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <Header />

            {/*  Page content */}
            <main className="ml-20 mt-20 pt-10">

                {/*  Page sections */}
                <>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                        noValidate
                        /* autoComplete="off" */
                    >
                        <div>
                            <TextField
                                id="current-password"
                                name="current-password"
                                label="旧密码"
                                type="password"
                                autoComplete="current-password"

                            />
                        </div>
                        <div>
                            <TextField
                                id="new-password"
                                name="new-password"
                                label="新密码"
                                type="password"
                                autoComplete="new-password"
                            />
                        </div>
                        <div>
                            <TextField
                                id="new-password-repeat"
                                name="new-password-repeat"
                                label="确认新密码"
                                type="password"
                                autoComplete="new-password"
                            />
                        </div>
                        <div>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}>修改密码</Button>
                        </div>

                        <Snackbar
                            open={snackContent !== ""}
                            autoHideDuration={3000}
                            onClose={handleCloseSnackbar}
                            message={snackContent}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                        />
                    </Box>
                </>
                {/* <FeaturesBlocks /> */}
                {/* <Testimonials /> */}
                {/* <Newsletter /> */}

            </main>

            {/*  Site footer */}
            {/* <Footer /> */}

            {confirmResetDialog}
        </div>
    );
}
