import React, { useState } from 'react';
import { getApiUrl, apiGetUserById } from '../utils/network.js';
import { UserContext } from '../App';

import Header from '../partials/Header';
import { useParams } from 'react-router';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

export default function AdminUser(props) {
    const { userId } = useParams();
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [password, setPassword] = React.useState("");

    const resetPassword = async (user) => {
        const currentUser = await apiGetUserById(userId, user.token);
        const url = getApiUrl('/api/v1/admin/password', { token: user.token })
        let password = currentUser.Username+userId+"_123";
        var passwordReq = {
            id: parseInt(userId),
            password: password,
        };
        // console.log(passwordReq)
        try {
            const response = await fetch(url, {
                headers: {
                    "Content-Type": "application/json",
                },
                method: 'PUT',
                body: JSON.stringify(passwordReq),
                mode: 'cors',
            })
            if (response.ok) {
                setPassword(password);
                setOpenSnackbar(true);
            }
        } catch (e) {
            console.log(e);
        }
    }


    const handleCloseDialog = (e) => {
        setOpenConfirmDialog(false);
        // if (e.currentTarget.value === 'yes') {
        // }
    }

    const handleCloseSnackbar = (event, reason) => {
        // if (reason === 'clickaway') {
        //     return;
        // }

        setOpenSnackbar(false);
    };


    const confirmResetDialog = (
        <UserContext.Consumer>
            {({ user, _, __ }) => (<>
                <Dialog
                    open={openConfirmDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"确定要重置密码吗？"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                            取消
                        </Button>
                        <Button value="yes" onClick={() => {
                            resetPassword(user);
                            setOpenConfirmDialog(false);
                        }} color="secondary" autoFocus>
                            确定
                        </Button>
                    </DialogActions>
                </Dialog>
            </>)
            }
        </UserContext.Consumer>
    );

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
            <Header />

            {/*  Page content */}
            <main className="ml-20 mt-20 pt-10">

                {/*  Page sections */}
                <>
                  <Button variant="contained" onClick={() => {setOpenConfirmDialog(true)}}>
                        重置密码
                    </Button>
                </>
                {/* <FeaturesBlocks /> */}
                {/* <Testimonials /> */}
                {/* <Newsletter /> */}

            </main>

            {/*  Site footer */}
            {/* <Footer /> */}

            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                message={"密码重置成功！"+password}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
            />
            {confirmResetDialog}
        </div>
    );
}
